import { createSlice } from "@reduxjs/toolkit";
import { auth } from '../firebase/config'

//console.log(auth?.currentUser)
const initialState = {
    user: auth?.currentUser
}
 
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        }       
       
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});
  
export const { setUser } = userSlice.actions;
export default userSlice.reducer;

// Connect state to the Store!!!