import { useState, useEffect } from 'react'
import s from '../Components/css/slider.module.css'
import { VideoIcon, ChatIcon, MusIcon, PostCardIcon } from './Icons'
import { toggleActiveTab } from '../Redux/activeTabSlice';
import  { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/languages/messages";


const TopTabsBtns = (  ) => {

 //    --- To Screen33 ---

    const dispatch = useDispatch()
    const persistedTab = useSelector(state => state.activeTab.activeTab)

    const toggleTab = value => {
        setActiveTab(value)
        dispatch(toggleActiveTab(value))
    }

    const [activeTab, setActiveTab] = useState(persistedTab || 2);

    useEffect(() => {
      setActiveTab(persistedTab)
// eslint-disable-next-line
    }, [])
   
  return (
 <div className={`${s.tabs} `}>
 
    <div className={`${s.tab_item} ${activeTab === 1 && s.tab_active}`} onClick={() => toggleTab(1)}>    
      {VideoIcon(30, activeTab !== 1 && 'var(--btn-color)')}   <FormattedMessage id='videoCong' defaultMessage={defineMessages.videoCong}/>
    </div>
   
 
    <div className={`${s.tab_item} ${activeTab === 2 && s.tab_active}`} onClick={() => toggleTab(2)}>
      {ChatIcon(30, activeTab !== 2 && 'var(--btn-color)')} <FormattedMessage id='txtCong' defaultMessage={defineMessages.txtCong}/>
    </div>
 
 
    <div className={`${s.tab_item} ${activeTab === 3 && s.tab_active}`} onClick={() => toggleTab(3)}>
      {MusIcon(30, activeTab !== 3 && 'var(--btn-color)')}  <FormattedMessage id='audioCong' defaultMessage={defineMessages.audioCong}/>
    </div>
  
    <div className={`${s.tab_item} ${activeTab === 4 && s.tab_active}`} onClick={() => toggleTab(4)}>
      {PostCardIcon(30, activeTab !== 4 && 'var(--btn-color)')}  <FormattedMessage id='cardCong' defaultMessage={defineMessages.cardCong}/>
    </div>
  
 </div>
  )
}

export default TopTabsBtns