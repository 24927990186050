import React from 'react'
import c from './css/screen2.module.css'
import traveller from '../assets/trophy-field.png'
import traveller1 from '../assets/trophy-1.png'
import photo_line from '../assets/phot-line-5-2.png'
import collage from '../assets/collage-2.png'

import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/languages/messages";

const Screen21 = () => {

 
  return (
    <div className={`${c.screen_wrapper} ${c.df_c_c} ${c.screen_max_w}`}>
      <div className={`${c.df_c_c} ${c.screen2_header}`}>
           <div className={c.hero_heart}>
             <img src={traveller} alt="hero_heart" className={c.hero_heart_img}/>
             <img src={traveller1} alt="hero_heart" className={c.cup_img}/>
           </div>
           <div className={c.header_txt_box}>
             <FormattedMessage id='sportH' defaultMessage={defineMessages.sportH}/>
           </div> 
           <div>
             <img src={photo_line} alt="hero_heart" className={c.photo_line_img}/>
           </div>
      </div>

      <div className={`${c.df_c_c} ${c.screen2_body} ${c.fx_adjust}`}>
          <div className={` ${c.body_r_side}`}>
            <div className={c.header_txt_box}>
             <img src={collage} alt="hero_heart" className={c.photo_line_img}/>
           </div> 
          </div>

        <div className={` ${c.body_l_side}`}>
            <div className={` ${c.body_l_side_header}`}>
             <FormattedMessage id='sport' defaultMessage={defineMessages.sport}/> <br/> <br/>        
           </div>

           <div className={` ${c.body_l_side_txt}`}>
             <p><FormattedMessage id='sport1' defaultMessage={defineMessages.sport1}/></p>
             <p><FormattedMessage id='sport2' defaultMessage={defineMessages.sport2}/></p>
             <p><FormattedMessage id='sport3' defaultMessage={defineMessages.sport3}/></p>
             <p><FormattedMessage id='sport4' defaultMessage={defineMessages.sport4}/></p>                            
           </div>
        </div>
      </div>
    </div>
  )
}

export default Screen21