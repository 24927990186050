import { createSlice } from "@reduxjs/toolkit";

const tabSaved = +localStorage.getItem('activeTab')
//console.log(bgSaved) 

const initialState = {
    activeTab: tabSaved || 2
}

export const activeTabSlice = createSlice({
    name: 'activeTab',
    initialState,
    reducers: {
        toggleActiveTab: (state, action) => {
            state.activeTab = action.payload;
           localStorage.setItem('activeTab', action.payload);
        },
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});
  
export const { toggleActiveTab } = activeTabSlice.actions;
export default activeTabSlice.reducer;

// Connect state to the Store!!!