
import c from "./App.module.css";

import HomeScreen from "./views/HomeScreen";
import PageRegControllers from './Components/MenuButtons/PageRegControllers'

// Ctrl+Shift+L - обрати увсі подібні записи
function App() {
 
  
  return (
    <div className={`${c.App}`}>
      
      <HomeScreen />
      <PageRegControllers />   
    </div>
  );
}

export default App;