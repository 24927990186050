import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { TrashIcon } from './Icons';
//import { delItemNoRef } from '../Functions';
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';


import db from '../firebase/config'
import { deleteDoc, doc } from 'firebase/firestore'



const DeleteConfirmForm = ( props ) => {

  //console.log(props.collName, props.docID)
////////////// Контроль отображения Модального окна
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
///////////////////////////////////////////////////

// Используется для перенаправления на нужную страницу сайта после удаленя элемента
//const car = sessionStorage.getItem('car');


const deliteRideAndCloseModal = () => {
  
  const docRef = doc(db, props.collName, props.docID)
  deleteDoc(docRef)
         
  setShow(false);  
};

    return (
        <>
        
             <div onClick={handleShow} >
              {TrashIcon()}
             </div> 
        
            

     <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='confDel' defaultMessage={defineMessages.confDel}/>
          </Modal.Title>
        </Modal.Header>        
       
       <h6 className='m-3 py-4'>
        <FormattedMessage id='del' defaultMessage={defineMessages.del}/>
       </h6>
          

         <Modal.Footer>
            <Button  variant={"info"} onClick={handleClose}>
              Cancel/Скасувати/Анулірам
            </Button>
            <Button variant={'danger'} onClick={deliteRideAndCloseModal} >
              Delete/Видалити/Изтрите
            </Button>
          </Modal.Footer>     
      </Modal>
            
        </>
    )
}

export default DeleteConfirmForm
