import React from 'react'
import { nanoid } from '@reduxjs/toolkit'
import c from './css/slider.module.css'

const SliderIndicatorsBox = ( { indiArray, displNum, setDisplNum }) => {
  return (
    <div className={c.slider_indicators_box}>
    {indiArray.map((i, index) => 
      <div className={`${c.slider_indicator_item} ${displNum === index + 1 && c.indicator_active}`} 
            onClick={() => setDisplNum(index + 1)}
            key={nanoid()} 
      />   
    )}
   </div>
  )
}

export default SliderIndicatorsBox