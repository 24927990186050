//import firebaseConfig from "./firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage"
//import { initializeApp } from 'firebase/app';
var config = { 
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MSG_SNDR_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Initialize firebase
const app = initializeApp(config);

// Initialize auth && firestore with the 'firebaseApp' property
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const firestore = getFirestore(app);
////// --- This one
export const fileStorage = getStorage(app);
export const storageRef = ref(fileStorage);
export const imagesRef = ref(fileStorage, 'forSlider')



//export const timestamp = firebaseApp.firestore.FieldValue.serverTimestamp;

//export default firebaseApp;
export default getFirestore()

// Initialize Firebase
//const app = initializeApp(firebaseConfig);

// Initialize Firestore
//const db = getFirestore(app);

//export { db };




// firebase.initializeApp(firebaseConfig);

// const projectStorage = firebase.storage();
// const projectFirestore = firebase.firestore();
 

// export { projectStorage, projectFirestore, timestamp };
// export default firebase;