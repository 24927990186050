import LangSelectHomeScreen from './LangSelectHomeScreen';



const PageRegControllers = ( ) => {

 
  return (
    <>
           
         
 
       <LangSelectHomeScreen />                          
                     
     
    </>
  )
}

export default PageRegControllers    
  