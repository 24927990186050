import { useState, useEffect } from 'react';
import db from '../firebase/config';
import { onSnapshot, collection, query,  orderBy } from 'firebase/firestore';

const useFirestore = (collect) => {

  const [docs, setDocs] = useState([]);
  const collectionRef = collection(db, collect)
  const q =  query(
            collectionRef, 
            orderBy('createdAt', 'desc'),
            //limit(5)
          )

  useEffect(() => {

    
    const unsub = onSnapshot(q, (snapshot) => {
      const items = [];
      snapshot.forEach((doc) => {
        console.log(doc.data())
        items.push({...doc.data(), id: doc.id})
      })
      setDocs(items)
    })
    return () => unsub();



    
    // const unsub = projectFirestore.collection(collection)
    //   .orderBy('createdAt', 'desc')
    //   .onSnapshot(snap => {
    //     let documents = [];
    //     snap.forEach(doc => {
    //       documents.push({...doc.data(), id: doc.id});
    //     });
    //     setDocs(documents);
    //   });

   
    // this is a cleanup function that react will run when
    // a component using the hook unmounts
// const [error, setError] = useState(null);
// eslint-disable-next-line
  }, [collect]);

  return { docs };
}

export default useFirestore; 