import React from 'react'
import c from './css/screen2.module.css'

import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/languages/messages";

const Screen4 = () => {
  //const vid = "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/images%2F20240723_134043.mp4?alt=media&token=1bb26c92-2524-45ac-9bce-ff02b09b8bc2";
 
  return (
    <div className={` ${c.df_c_c} `}>

                {/* <video  controls="controls" className={c.vid_window}>                    
                    <source src={vid} type="video/mp4"/>
                </video>  */}

      <div className={` ${c.screen_4_bg} ${c.screen4_body} ${c.scree4_mob_adjust}`}>
        <div className={c.screen4_mob_div}/>
          <div className={`${c.df_c_c} ${c.screen4_txt_box}`}>
            <div className={`${c.screen4_txt_header}`}>
              <FormattedMessage id='conclH1' defaultMessage={defineMessages.conclH1}/><br/>
              <FormattedMessage id='conclH2' defaultMessage={defineMessages.conclH2}/><br/>
              <FormattedMessage id='conclH3' defaultMessage={defineMessages.conclH3}/><br/>
            </div>
            <div className={`${c.screen4_txt_body}`}>
                <p><FormattedMessage id='concl1' defaultMessage={defineMessages.concl1}/></p>
                <p><FormattedMessage id='concl2' defaultMessage={defineMessages.concl2}/></p>
                <p><FormattedMessage id='concl3' defaultMessage={defineMessages.concl3}/></p>
                <p><FormattedMessage id='concl4' defaultMessage={defineMessages.concl4}/></p>
            </div>
       </div>
      </div> 
    </div>
  )
}

export default Screen4