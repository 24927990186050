import React from 'react'
import c from './css/screens.module.css'
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
import  { useSelector } from 'react-redux'
import heart from '../assets/hero_heart.jpg'
import karateka from '../assets/hero_karateka.jpg'
import bPp from '../assets/blue-push-pin.png'
import yPp from '../assets/yellow-push-pin.png'
import pClip from '../assets/paper_clip.png'
import rSide from '../assets/hero_r_side.png'
import { ArrowIcon } from '../Components/Icons'

const Screen1 = () => {

   
    const triger = useSelector((state) => state.triger.triger)
   // console.log(triger)
   
  return (
    <div className={`${c.screen_wrapper} ${c.screen_fill}`}>
      <div className={c.screen1_adjuster}> 
       <div className={c.hero_l_side}>          
        <div className={`${c.hb_container_motion} `}>
           <div className={c.hero_heart}>
             <img src={heart} alt="hero_heart" className={c.hero_heart_img}/>
           </div>
           <div className={`${c.hero_txt} ${c.hero_txt_fill}`}>
            <FormattedMessage id='hb' defaultMessage={defineMessages.hb}/><br/>  
            <FormattedMessage id='hb2' defaultMessage={defineMessages.hb2}/>         
           </div> 
           <div className={`${c.hero_txt}`}>
            <span><FormattedMessage id='yd' defaultMessage={defineMessages.yd}/> !!!</span>           
           </div>  
        </div> 

          
            
              <div className={` ${c.hero_white_card}`}>
                <div className={`${c.hero_heart} ${c.hero_karateka}`}>
                  <img src={karateka} alt="hero_heart" className={c.hero_heart_img}/>
                </div>
                <div className={`${c.hero_pins} ${c.hero_pClip}`}>
                  <img src={pClip} alt="hero_heart" className={c.hero_pClip_img}/>
                </div>
              {triger !== 'ua' && <div className={c.ballast} />}
               <FormattedMessage id='wcome' defaultMessage={defineMessages.wcome}/>   
                <br/>               
                <FormattedMessage id='yds' defaultMessage={defineMessages.yds}/>
               {triger === 'ua' && <br/>}
                <span className={c.bg_purple}> <FormattedMessage id='bd15' defaultMessage={defineMessages.bd15}/></span>              
              </div>              
           
         
           
          <div className={` ${c.hero_white_card} ${c.hero_white_card2}`}> 
               <div className={`${c.hero_pins} ${c.hero_bPp}`}>
                  <img src={bPp} alt="hero_heart" className={c.hero_pClip_img}/>
               </div>          
             <FormattedMessage id='tday' defaultMessage={defineMessages.tday}/>  {triger === 'ua' && <br/>}
             {triger === "ar" || triger === "en" ? (
              <><span className={c.bg_purple}> <FormattedMessage id='tday1' defaultMessage={defineMessages.tday1}/><br/>
                </span></>
            ) : null}
             <FormattedMessage id='tday2' defaultMessage={defineMessages.tday2}/><br/>
             <span className={triger === "ua" && c.bg_purple}> <FormattedMessage id='tday3' defaultMessage={defineMessages.tday3}/></span> 
           </div> 

           <div className={` ${c.hero_white_card} ${c.hero_white_card3}`}>
            <div>
               <div className={`${c.hero_pins} ${c.hero_yPp}`}>
                  <img src={yPp} alt="hero_heart" className={c.hero_pClip_img}/>
               </div> 
              <FormattedMessage id='tws' defaultMessage={defineMessages.tws}/><br/>
              <FormattedMessage id='tws2' defaultMessage={defineMessages.tws}/><br/><br/>
              
              {triger === "ua" ? (
                <>
                <span className={c.bg_purple}><FormattedMessage id='jus' defaultMessage={defineMessages.jus}/></span>
                <span> <FormattedMessage id='jus2' defaultMessage={defineMessages.jus2}/> </span> <br/>
                <FormattedMessage id='jus3' defaultMessage={defineMessages.jus3}/> 
                <FormattedMessage id='jus4' defaultMessage={defineMessages.jus4}/>   
                </>
              ) : (
                <>
                 <span className={c.bg_purple}><FormattedMessage id='jus' defaultMessage={defineMessages.jus}/></span>
                <span> <FormattedMessage id='jus2' defaultMessage={defineMessages.jus2}/></span> <br/>
                <FormattedMessage id='jus3' defaultMessage={defineMessages.jus3}/> <br/>  
                {triger === "ar" && <FormattedMessage id='jus4' defaultMessage={defineMessages.jus4}/>}
                </>
              )}
           </div> 
          </div>

        </div>

          <div className={c.hero_r_side}>
            <img src={rSide} alt="hero_heart" className={c.hero_rside_img}/>
          
           {/* <img src={rSide} alt="hero_heart" className={c.hero_img_mobview}/> */}
          </div> 

          <div className={`${c.disp_flx_ctr} ${c.btm_arrow} `}>
              {ArrowIcon(30)}
          </div>
         
        </div>
        
      </div>
   
  )
}

export default Screen1