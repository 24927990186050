import React, { useState, useEffect }  from 'react';
import c from './video_style.module.css';
import s from './modal.module.css'
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';
import { 
        Container, 
        Button, 
        Modal, 
        // Carousel, 
        // CarouselItem, 
        // Image 
        } from 'react-bootstrap';
import ModalFormline from './ModalFormline';
import ImgPicker from './ImgPicker';
import MediaPicker from './MediaPicker'
import db, { auth, googleProvider } from '../firebase/config'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { setUser } from '../Redux/userSlice';
import  { useSelector, useDispatch } from 'react-redux'
import { GiftIcon } from './Icons'

const AddCongratModal = (props) => {

    const user = useSelector((state) => state.user.user)
    //console.log(user.uid)
    const dispatch = useDispatch()

    const [showModalForm, setShowModalForm] = useState(false);
   
  
   // const [alertSuccess, setAlertSuccess] = useState(false);
   const [name, setName] = useState(user?.displayName || '');    
   const [userImg, setUserImg] = useState(user?.photoURL || '');
   const [msg, setMsg] = useState(props.msg || '');
   const [image, setImage] = useState(props.image || '');
   const [video, setVideo] = useState(props.video || '');
   const [music, setMusic] = useState(props.music || '');
   
   const [mediaType, setMediaType] = useState([]);

   const [nameEmty, setNameEmpty] = useState(false);  
   const [avatarEmpty, setAvatarEmpty] = useState(false);
   const [msgEmpty, setMsgEmpty] = useState(false);
 
   const [addText, setAddText] = useState(false);
   const [addImg, setAddImg] = useState(false);
   const [addVid, setAddVid] = useState(false);
   const [addMus, setAddMus] = useState(false);

   const [videoType, setVideoType] = useState('');
   const [audioType, setAudioType] = useState('');

//    const [, set] = useState(props. || '');

    const [loadingImg, setLoadingImg] = useState(false); 
   
    const [showUrl, setShowUrl] = useState('');

    const signIn = async () => {
      try {
        await signInWithPopup(auth, googleProvider).then(
          (userCredential) => {
            const user = userCredential.user;
            console.log(user)           
          }
        )
       // console.log(auth?.currentUser)
        //
      } catch (err) {
        console.error(err);
      }
    }
    //console.log(user)
    const initialize = () => {
      if (auth?.currentUser) {
        setShowModalForm(true)
      } else {
        signIn()
      }
    }
    const fileType = (data) => {
        setAvatarEmpty(false)
       }; 
    const onMsgChange = (e) => {
        setMsg(e) 
        setMsgEmpty(false)       
    }
    const onNameChange = (e) => {
        setName(e)  
        setNameEmpty(false)     
    }  
  
    const handleChangeAvatar = () => {
      setUserImg('')
      setShowUrl(false)
    }
    const handleChangeVideo = () => {
      setVideo(null)
      setAddVid(true)
      setShowUrl(false)
    }
    const handleChangeAudio = () => {
      setMusic(null)
      setAddMus(true)
      setShowUrl(false)
    }
    const handleChangeMedia = () => {
      setImage('')
      setShowUrl(false)
    }

    const handleAddText = () => {
      setAddText(true)
      if(!mediaType.includes('text')) {
        setMediaType([...mediaType, 'text']) 
      } else { return }           
    }
    const handleAddImg = () => {
      setAddImg(true)
      if(!mediaType.includes('img')) {
        setMediaType([...mediaType, 'img']) 
      } else { return }           
    }
    const handleAddVid = () => {      
      setAddVid(true)
      if(!mediaType.includes('vid')) {
        setMediaType([...mediaType, 'vid']) 
      } else { return }           
    }
    const handleAddMus = () => {
      setAddMus(true)
      if(!mediaType.includes('mus')) {
        setMediaType([...mediaType, 'mus']) 
      } else { return }           
    }

    const onModalClose = () => {
        setMsg(null)        
        setImage(null)
        setShowUrl(false)
        setNameEmpty(false)      
        setAvatarEmpty(false)
        setMsgEmpty(false)
        if(!user) {
          setName(null)
          setUserImg(null)
        }
        setVideo('');
        setMusic('');

        setAddText(false);
        setAddImg(false);
        setAddVid(false);
        setAddMus(false);

        setMediaType([]);
        
        setImage(null)
        setShowModalForm(false) 
    }

    const addNewCongrat = async (data) => {      
      const collectionRef = collection(db, 'Congrats')
      await addDoc(collectionRef, data)
    }

    const onPostMsg = () => {
      if(!name) {
        setNameEmpty(true)      
      } if(!userImg) {
        setAvatarEmpty(true)       
      } if(mediaType.includes('mus') && !music) {
        return
      } if(mediaType.includes('vid') && !video) {
        return
      } if(addText && !msg) {
        setMsgEmpty(true)
        return
      } else {
        let data = {
            userId: user.uid,
            userImg,
            name,          
            msg,
            mediaType,
            image,
            video,
            videoType: videoType,
            music,
            audioType: audioType,
            createdAt: serverTimestamp(),
        }
    //console.log(data) 
    addNewCongrat(data)

        setMsg('')        
        setImage('')
        setShowUrl(false)
          if(!user) {
            setName('')
            setUserImg('')            
          }
        setShowModalForm(false)
      }
               
    }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user) {
       // console.log(user)
        dispatch(setUser(user))
        setName(user.displayName)
        setUserImg(user.photoURL)
      }
    })
    return () => unsubscribe()
  }, [])
   

  return (
    <div>
         <Button variant='success' onClick={() => initialize()} className={s.lower_btn}>
           <span >{GiftIcon(30)}</span> 
           <FormattedMessage id='addCong' defaultMessage={defineMessages.addCong}/> 
         </Button> 
              
        
        <Modal          
          show={showModalForm}
          onHide={() => setShowModalForm(false)} 
          size="xl"
         
          >
            <Modal.Header className={s.modal_header}>
             <h5>
                <FormattedMessage id='yourscongr' defaultMessage={defineMessages.yourscongr}/>
             </h5>              
            </Modal.Header>
         
           <Modal.Body  className={s.modal}>
             <Container className={s.modal_form}>
               
                <ModalFormline 
                    header={<FormattedMessage id='name' defaultMessage={defineMessages.name}/>}
                    placeholder={'Your Name / Ваше Ім\'я / Вашето Име'}
                    value={name}
                    onChange={onNameChange}
                    type={'text'}
                    empty={nameEmty}
                />   


               {!userImg ? 
                  (<ModalFormline 
                    image={true}
                    header={<FormattedMessage id='avatar' defaultMessage={defineMessages.avatar}/>}
                  
                  >
                    <ImgPicker 
                      setImage={setUserImg}
                      setShowUrl={setShowUrl} 
                      showUrl={showUrl}
                      setFileType={fileType}
                      setLoadingImg={setLoadingImg}
                      userImg={true}
                      empty={avatarEmpty}
                    />
                  </ModalFormline>) : (                   
                    <div className={`${s.img_btn_cont}`} > 
                     <div className={`${s.img_btn_cont_subdiv}`}>
                       <Button className={`rounded ml-4`} variant='info' onClick={() => handleChangeAvatar()}>
                         <FormattedMessage id='changeAv' defaultMessage={defineMessages.changeAv}/>
                       </Button>
                     </div>
                     <div className={`mt-3 ${s.img_btn_cont_subdiv}`}>
                       <img src={userImg} alt='img provided by Google' style={{ width:100 }} />
                     </div>
                    </div>
                   
                  )
                }

                <div className={`my-4`} >
                    <h6>
                      <FormattedMessage id='whatToAdd' defaultMessage={defineMessages.whatToAdd}/>
                    </h6>                   
                    <div className={`rounded mt-3`}>
                      <Button variant='info' className={`rounded mt-3`} onClick={() => handleAddText()} disabled={addText}>
                        <FormattedMessage id='txt' defaultMessage={defineMessages.txt}/>
                      </Button>
                      <Button variant='info' className={`rounded ml-4 mt-3`} onClick={() => handleAddImg()} disabled={addImg}>
                        <FormattedMessage id='img' defaultMessage={defineMessages.img}/> / gif
                      </Button>
                      <Button variant='info' className={`rounded mx-4 mt-3`} onClick={() => handleAddVid()} disabled={addVid}>
                        <FormattedMessage id='vid' defaultMessage={defineMessages.vid}/>
                      </Button>
                      <Button variant='info' className={`rounded mt-3`} onClick={() => handleAddMus()} disabled={addMus}>
                        <FormattedMessage id='mus' defaultMessage={defineMessages.mus}/>
                      </Button>
                    </div>
                </div>
          


                 {addText && 
                  <ModalFormline 
                      header={<FormattedMessage id='msg' defaultMessage={defineMessages.msg}/>}
                      placeholder={'Your Message / Ваше Вітання / Вашето съобщение'}
                      value={msg}
                      onChange={onMsgChange}
                      type={'text'}
                      area={true}
                      rows={4}
                      empty={msgEmpty}
                  /> 
                }

                {addImg && 
                  <>
                    {!image ? (
                    <ModalFormline 
                    image={true}
                    header={<FormattedMessage id='media' defaultMessage={defineMessages.media}/>}
                    >
                    <ImgPicker 
                      setImage={setImage} 
                      setLoadingImg={setLoadingImg} 
                      setShowUrl={setShowUrl} 
                      showUrl={showUrl}
                      setFileType={fileType}
                      userImg={false}
                    />
                    </ModalFormline>
                    ) : (
                      <div className={`${s.img_btn_cont}`} > 
                      <div className={`${s.img_btn_cont_subdiv}`}>
                        <Button className={`rounded ml-4`} variant='info' onClick={() => handleChangeMedia()}>
                          <FormattedMessage id='anothFile' defaultMessage={defineMessages.anothFile}/>
                        </Button>
                      </div>
                      <div className={`${s.img_btn_cont_subdiv}`}>
                        <img src={image} alt='provided by Google' style={{ width:300 }} />
                      </div>
                    </div>
                    )}
                 </>
                }

                {addVid && !video ?
                 (<ModalFormline
                   image={true} 
                   header={<FormattedMessage id='media' defaultMessage={defineMessages.media}/>}
                  >                  
                 <MediaPicker 
                   setImage={setVideo} 
                   setLoadingImg={setLoadingImg} 
                   setShowUrl={setShowUrl} 
                   showUrl={showUrl}
                   setFileType={fileType}
                   userImg={false}
                   video={true}
                   playVideo={video}
                   setMediaType={setVideoType}
                 />
                 </ModalFormline> )
                 : 
                 ( mediaType.includes('vid') && 
                 <div className={`${s.img_btn_cont}`} > 
                  <div className={`${s.img_btn_cont_subdiv}`}>
                    <Button className={`rounded ml-4`} variant='info' onClick={() => handleChangeVideo()}>
                      <FormattedMessage id='anothFile' defaultMessage={defineMessages.anothFile}/>
                    </Button>
                  </div>
                    <video width="345" height="160" controls>
                       <source src={video} type={videoType} />                  
                    </video> 
                 </div>) 
                }
<br/>
                {addMus && !music ? (
                    <ModalFormline
                    image={true} 
                    header={<FormattedMessage id='media' defaultMessage={defineMessages.media}/>}
                   >                  
                    <MediaPicker 
                      setImage={setMusic} 
                      setLoadingImg={setLoadingImg} 
                      setShowUrl={setShowUrl} 
                      showUrl={showUrl}
                      setFileType={fileType}
                      userImg={false}
                      mus={true}
                      playMus={music}
                      setMediaType={setAudioType}
                    />
                   </ModalFormline>
                  ) : (mediaType.includes('mus') &&
                    <div className={`${s.img_btn_cont}`} > 
                    <div className={`${s.img_btn_cont_subdiv}`}>
                      <Button className={`rounded ml-4`} variant='info' onClick={() => handleChangeAudio()}>
                        <FormattedMessage id='anothFile' defaultMessage={defineMessages.anothFile}/>
                      </Button>
                    </div>
                       <audio controls>
                          <source src={music} type={audioType}/>
                       </audio>  
                   </div>
                  )
                }
                
                
               

                                 
                   
                      
             </Container>
           </Modal.Body>

           <Modal.Footer  className={`pb-3 ${s.modal_footer}`}>
              <Button onClick={() => onModalClose()} variant="danger" className={`mt-3 ${c.btn}`}>
                <FormattedMessage id='cancel' defaultMessage={defineMessages.cancel}/>
              </Button> 
             
              <Button 
                onClick={() => onPostMsg()} 
                variant="success" 
                className={`ml-5 mt-3 ${c.btn}`}
                disabled={loadingImg || (mediaType?.length === 0) || (mediaType.includes('text') && msg?.length === 0)}
                >
                <FormattedMessage id='postmsg' defaultMessage={defineMessages.postmsg}/>
              </Button>          
          </Modal.Footer>

         </Modal>
    </div>
  )
} 

export default AddCongratModal