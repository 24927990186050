import React from 'react'
import c from './css/screen2.module.css'
import traveller from '../assets/trophy-field.png'
import traveller1 from '../assets/photos.png'
import photo_line from '../assets/banner.png'


import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/languages/messages";
import SliderBig from '../Components/SliderBig'

const Screen22 = () => {

 
  return (
    <div className={`${c.screen_wrapper} ${c.df_c_c}`}>
      <div className={`${c.df_c_c} ${c.screen2_header} ${c.screen_max_w}`}>
           <div className={c.hero_heart}>
             <img src={traveller} alt="hero_heart" className={c.hero_heart_img}/>
             <img src={traveller1} alt="hero_heart" className={c.cup_img}/>
           </div>
           <div className={c.header_txt_box}>
             <FormattedMessage id='memo' defaultMessage={defineMessages.memo}/>
           </div>           
             <img src={photo_line} alt="hero_heart" className={c.photo_line_img}/>           
      </div>

      <div className={`${c.df_c_c} `}>                  
              <SliderBig />
      </div> 
    </div>
  )
}

export default Screen22