import React from 'react';
import s from './modal.module.css'
import {    
    Row,
    Col,    
    InputGroup,
    FormControl, 
  } from 'react-bootstrap';
/////////////////////////////////// Этот компонент, это просто -
///////////////////////// кусок верстки, содержащий Bootstrap раскладку
/////////////////////// часто переиспользуемую в компоненте  AddNewRideModal
const ModalFormline = (props) => {

  //console.log(props.placeholder)
    return (
        <Row>
            <Col className="my-4" sm={12} md={6}>
              <h6 className={`pb-1`}>
                {props.header}
              </h6> 
            </Col>
            <Col  sm={12} md={6}>
              <InputGroup className=""> 
              {props.image ? props.children : ( 
                <FormControl 
                  className={`rounded ${props.area && 'mb-4'} ${props.empty && s.empty}`} 
                  placeholder={props.placeholder} 
                  value={props.value} 
                  onChange={(event) => props.onChange(event.target.value)} 
                  type={props.type}
                  as={props.area && 'textarea'}
                  rows={props.rows && props.rows}
                  />
                )}  
              </InputGroup>
            </Col>
          </Row>
    )
}

export default ModalFormline
