



export const testValues = [
    {       
        createdAt: {seconds: 1721411017, nanoseconds: 49000000},
        id: "NiVxJ6MuENFGX6JrMM9c",
        image: "",
        mediaType: ['text'],
        msg:"111  З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️ З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️",
        music: "",
        name: "Irina Agura",
        userId:"LPTsbsuKu1M31zixOzmOateNh2h2",
        userImg: "https://lh3.googleusercontent.com/a/ACg8ocKxyj_j69GbJ01Oz5Oh3lUiXPWKYpoZqrG1lf_QJT6QHmdvr38A=s96-c",
        video: "",
        videoType: "", 
        audioType:"",
    },
    {       
        createdAt: {seconds: 1721411017, nanoseconds: 49000000},
        id: "NiVxJ6MuENFGX6JrMM9c",
        image: '',
        mediaType: ['text', 'mus', 'vid', 'img'],
        msg:"222   З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️",
        music: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/gims_horizon.mp3?alt=media&token=3ef21e04-76b8-43c0-9d93-93214eceb8c5",
        name: "Denys Glukhovsky",
        userId:"lXNLOwilBnYusAQrwCz0ehUZ0A12",
        userImg: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/images%2F8881.jpg?alt=media&token=ab127f6f-edb3-4645-8631-715b37eeac97",
        video: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/ann_vid.mp4?alt=media&token=5b0ee4ea-e5f6-4882-b479-835bf4c2a329",
        videoType: "video/mp4", 
        audioType:"audio/mpeg",
    },
    {       
        createdAt: {seconds: 1721411017, nanoseconds: 49000000},
        id: "NiVxJ6MuENFGX6JrMM9c",
        image: '',
        mediaType: ['text', 'mus', 'vid', 'img'],
        msg:"333  З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️",
        music: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/gims_horizon.mp3?alt=media&token=3ef21e04-76b8-43c0-9d93-93214eceb8c5",
        name: "Denys Glukhovsky",
        userId:"lXNLOwilBnYusAQrwCz0ehUZ0A12",
        userImg: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/images%2F8881.jpg?alt=media&token=ab127f6f-edb3-4645-8631-715b37eeac97",
        video: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/ann_vid.mp4?alt=media&token=5b0ee4ea-e5f6-4882-b479-835bf4c2a329",
          videoType: "video/mp4", 
        audioType:"audio/mpeg",
    },
    {       
        createdAt: {seconds: 1721411017, nanoseconds: 49000000},
        id: "NiVxJ6MuENFGX6JrMM9c",
        image: '',
        mediaType: ['text', 'mus', 'vid', 'img'],
        msg:"4 З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️",
        music: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/gims_horizon.mp3?alt=media&token=3ef21e04-76b8-43c0-9d93-93214eceb8c5",
        name: "Denys Glukhovsky",
        userId:"lXNLOwilBnYusAQrwCz0ehUZ0A12",
        userImg: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/images%2F8881.jpg?alt=media&token=ab127f6f-edb3-4645-8631-715b37eeac97",
        video: '',
          videoType: "video/mp4", 
        audioType:"audio/mpeg",
    },
    {       
        createdAt: {seconds: 1721411017, nanoseconds: 49000000},
        id: "NiVxJ6MuENFGX6JrMM9c",
        image: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover1.jpg?alt=media&token=ce8552ff-cd63-45c5-8b13-859c3bf23283",
        mediaType: ['text', 'mus', 'vid', 'img'],
        msg:"555 З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️",
        music: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/gims_horizon.mp3?alt=media&token=3ef21e04-76b8-43c0-9d93-93214eceb8c5",
        name: "Denys Glukhovsky",
        userId:"lXNLOwilBnYusAQrwCz0ehUZ0A12",
        userImg: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/images%2F8881.jpg?alt=media&token=ab127f6f-edb3-4645-8631-715b37eeac97",
        video: '',
          videoType: "video/mp4", 
        audioType:"audio/mpeg",
    },
    {       
        createdAt: {seconds: 1721411017, nanoseconds: 49000000},
        id: "NiVxJ6MuENFGX6JrMM9c",
        image: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover1.jpg?alt=media&token=ce8552ff-cd63-45c5-8b13-859c3bf23283",
        mediaType: ['text', 'mus', 'vid', 'img'],
        msg:"6 З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️",
        music: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/gims_horizon.mp3?alt=media&token=3ef21e04-76b8-43c0-9d93-93214eceb8c5",
        name: "Denys Glukhovsky",
        userId:"lXNLOwilBnYusAQrwCz0ehUZ0A12",
        userImg: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/images%2F8881.jpg?alt=media&token=ab127f6f-edb3-4645-8631-715b37eeac97",
        video: '',
          videoType: "video/mp4", 
        audioType:"audio/mpeg",
    },
    {       
        createdAt: {seconds: 1721411017, nanoseconds: 49000000},
        id: "NiVxJ6MuENFGX6JrMM9c",
        image: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover1.jpg?alt=media&token=ce8552ff-cd63-45c5-8b13-859c3bf23283",
        mediaType: ['text', 'mus', 'vid', 'img'],
        msg:"777 З днем народження, дорогенькі дівчатка! Нехай вам у житті завжди щастить, хай друзі будуть найкращі, а обрана дорога цікавою та приємною. З любовʼю Іра, Андрій та Дмитро ❤️",
        music: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/gims_horizon.mp3?alt=media&token=3ef21e04-76b8-43c0-9d93-93214eceb8c5",
        name: "Denys Glukhovsky",
        userId:"lXNLOwilBnYusAQrwCz0ehUZ0A12",
        userImg: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/images%2F8881.jpg?alt=media&token=ab127f6f-edb3-4645-8631-715b37eeac97",
        video: '',
          videoType: "video/mp4", 
        audioType:"audio/mpeg",
    },
    {       
        createdAt: {seconds: 1721411017, nanoseconds: 49000000},
        id: "NiVxJ6MuENFGX6JrMM9c",
        image: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/cover1.jpg?alt=media&token=ce8552ff-cd63-45c5-8b13-859c3bf23283",
        mediaType: ['img'],
        msg:'',
        music: '',
        name: "Denys Glukhovsky",
        userId:"lXNLOwilBnYusAQrwCz0ehUZ0A12",
        userImg: "https://firebasestorage.googleapis.com/v0/b/happybirthday-532c3.appspot.com/o/images%2F8881.jpg?alt=media&token=ab127f6f-edb3-4645-8631-715b37eeac97",
        video: '',
        videoType: '', 
        audioType:'',
    }
]

export const testImages2 = [
                        ]