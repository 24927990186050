import { useState, useEffect } from 'react'
import c from './css/screen2.module.css'
import s from '../Components/css/slider.module.css'
import traveller from '../assets/trophy-field.png'
import traveller1 from '../assets/party.png'

import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/languages/messages";

import TopTabsBtns from '../Components/TopTabsBtns';
//import Slider2 from '../Components/Slider2'
import AddCongratModal from '../Components/AddCongratModal'
import { useSelector } from 'react-redux'
import TxtMsgs from '../Components/TxtMsgs'
import useFirestore from '../Hooks/useFirestore';

//import { testValues } from './TestValues'

const Screen33 = () => {

 const activeTab = useSelector((state) => state.activeTab.activeTab)
 const [messages, setMessages] = useState([])
  const [txtMsgs, setTxtMsgs] = useState([])
  const [vidMsgs, setVidMsgs] = useState([])
  const [musMsgs, setMusMsgs] = useState([])
  const [imgMsgs, setImgMsgs] = useState([])

 const { docs } = useFirestore('Congrats')
 

 useEffect(() => {
  setMessages(docs)  
  //console.log(docs) 
  console.log(messages)
// eslint-disable-next-line 
}, [docs])

useEffect(() => {
 
  setTxtMsgs(messages.filter(item => item.msg?.length > 0))
  setVidMsgs(messages.filter(item => item.video !== ''))
  //setVidMsgs(videos)
  setMusMsgs(messages.filter(item => item.music !== ''))
  setImgMsgs(messages.filter(item => item.image?.length > 0))
  console.log( txtMsgs, vidMsgs, musMsgs, imgMsgs,messages,)
// eslint-disable-next-line 
}, [messages])
//Tut otrimuyu danni ta peredayu ih po componentah


// console.log(vidMsgs)
// console.log(musMsgs) 
// console.log(imgMsgs)

  return (
    <div className={`${c.screen_wrapper} ${c.df_c_c} `}>
      <div className={`${c.df_c_c} ${c.screen2_header}`}>
           <div className={c.hero_heart}>
             <img src={traveller} alt="hero_heart" className={c.hero_heart_img}/>
             <img src={traveller1} alt="hero_heart" className={c.cup_img}/>
           </div>
           <div className={c.header_txt_box}>
             <FormattedMessage id='cong1' defaultMessage={defineMessages.cong1}/><br/>
             <FormattedMessage id='cong2' defaultMessage={defineMessages.cong2}/>
           </div>            
      </div>
          <TopTabsBtns  />

      <div className={`${c.df_c_c} ${c.screen_33_body} ${c.screen_33_bg}`}>

        <div className={`${c.df_c_c} ${s.slider_2_box}`}>
           {/* {activeTab === 1 && <Slider2 dataVid={vidMsgs}/>} */}
       
                    <TxtMsgs
                      dataVid={vidMsgs} 
                      dataTxt={txtMsgs} 
                      dataMus={musMsgs} 
                      dataImg={imgMsgs} 
                      activeTab={activeTab}
                      messages={messages}
                    />
                    
        </div>

        <div className={c.lower_btn_cont}>  
          <AddCongratModal />
        </div>
        <div className={c.plane_mob}/>        
      </div> 
    </div>
  )
}

export default Screen33