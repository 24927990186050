import { useState, useEffect } from 'react'
import c from './css/tabs.module.css'

import TxtCardBody from './TxtCardBody'
import SliderIndicatorsBox from './SliderIndicatorsBox'
import SliderLeftBtn from './SliderLeftBtn'
import SliderRightBtn from './SliderRightBtn'
import SpinnerBig from './SpinnerBig'
import  { useDispatch } from 'react-redux'
import { toggleActiveTab } from '../Redux/activeTabSlice';

const TxtMsgs = ({dataTxt, dataMus, dataImg, dataVid, activeTab, messages}) => {

  const dispatch = useDispatch()

  console.log(messages, dataTxt, dataMus, dataImg, dataVid)

    const [data, setData] = useState([])
    const [displNum, setDisplNum] = useState(1) //nomer vidibrazh ekranu - diskretna kilkist mesages/ msgs/screen
// eslint-disable-next-line
    const [msgsPerScreen, setMsgsPerScreen] = useState(2) // Znadobitsya dlya mob versii !!!
    const [addDispl, setAddDispl] = useState(false)//Liva knopka ( - )
    const [substDispl, setSubstDispl] = useState(false)// Prava knopka ( + )
    const [indicators, setIndicators] = useState(data?.length)//kil'kist' msages
    const [indiArray, setIndiArray] = useState([]);//kilkist krapok indikatoriv
    const [dataForNum1, setDataForNum1] = useState({})
    const [dataForNum2, setDataForNum2] = useState({})

    ////////////////////////////////////////////////////////////////
    const [displWidth, setDisplWidth] = useState(window.innerWidth)
    const handleResize = () => {
      setDisplWidth(window.innerWidth)
    }

    useEffect(() => {
      if(messages?.length > 0) {
         dispatch(toggleActiveTab(4))
        setTimeout(() => {
         dispatch(toggleActiveTab(2))
        }, 50)
      }
    }, [messages])

    useEffect(() => {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)        
      }      
    },[])
////////////////////////////////////
    useEffect(() => {
      if(displWidth > 1400) {
        setMsgsPerScreen(6)
      } else if(displWidth <= 1400) {
        setMsgsPerScreen(2)
      }
    },[displWidth])
///////////////////////////////////////
////////////////////////////////////
useEffect(() => {
  if(displWidth > 1400) {
    setMsgsPerScreen(2)
  } else if(displWidth <= 1400) {
    setMsgsPerScreen(1)
  }
},[displWidth])
////////////////////////////////////////



   
useEffect(() => {
  if(activeTab === 1) {
    setData(dataVid)
   // setDisplNum(2) 
     setSubstDispl(true)  /////////////// !!!!!!!!!!!!!!!!!!!!!!! Mozhe i ne buti !!!!!!!!! 
   } else if(activeTab === 2) {
  setData(dataTxt)
    //    setDisplNum(2)   
 } else if(activeTab === 3) {
  setData(dataMus)
        setDisplNum(2) ////////////// !!!!!!!!!!!!!!!!!!!!!!! Mozhe i ne buti !!!!!!!!!
 } else if(activeTab === 4) {
  setData(dataImg)  
 }
// eslint-disable-next-line
}, [activeTab])

  useEffect(() =>{  
   if(msgsPerScreen === 2) {
     setDataForNum1(data[((displNum - 1) * msgsPerScreen)])
     setDataForNum2(data[((displNum - 1) * msgsPerScreen) + 1])
   }  else {
    setDataForNum1(data[((displNum - 1) * msgsPerScreen)])
   } 
     
// eslint-disable-next-line      
 }, [displNum, messages])
//////////////////////////////////////
useEffect(() => {
  if(data) { 
    setIndicators([])
    setIndicators(data?.length)
  }  // Vstanovimo kilkist indikators ob otrimannin data z serveru
}, [data, activeTab, messages])

useEffect(() => {
  setIndiArray([])
}, [activeTab])

useEffect(() => {
  if(!data) {
    setIndiArray([])
    setIndicators([])
  }
  if(indicators > 0) { 
    if(indiArray?.length >= data?.length / msgsPerScreen) {
      return
    } else {
      setIndicators(prev => prev - msgsPerScreen) // zmenshue kilkist msgs na msgs/screen
    setIndiArray([...indiArray, 'i']) //vidibrazhae 1 krapku poki indicators > 0 
    }             
  }
// eslint-disable-next-line  
}, [indicators, data, messages])
/////////////////////////////////////// ---

///////////////// Robota knopok + / -
useEffect(() =>{
  if(addDispl) {
      if(((displNum) * msgsPerScreen) < data?.length) {         
         setDisplNum(prev => prev + 1)
         setAddDispl(false)                                   
      } else {
          setDisplNum(1)
          setAddDispl(false)       
      }      
  } else if(substDispl) {
      if((displNum - 1) >= 1) {    
        setDisplNum(prev => prev - 1)
        setSubstDispl(false)                 
      } else {
          if(Math.floor(data?.length / msgsPerScreen) === data?.length / msgsPerScreen) {
      //    
              setDisplNum(Math.floor(data?.length / msgsPerScreen))
              setSubstDispl(false)           
          } else {
              setDisplNum(Math.floor(data?.length / msgsPerScreen) + 1)
              setSubstDispl(false)                           
          }    
      }
  }
// eslint-disable-next-line
}, [addDispl, substDispl, messages])
///////////////////////////////////////////////////////////////
useEffect(() => { 
     setTimeout(() => {
      setAddDispl(true)    
     }, 500)  
}, [messages])
 
  return (
    <div className={c.cards_wrapper}>
      {messages?.length === 0 ? 
      <SpinnerBig /> : <>
    

      <div className={c.cards_body_holder}>
        <div className={c.mob_rmv_btns}>
           <SliderLeftBtn action={() => setSubstDispl(true)} />
        </div>
       
        <div className={c.cards_holder}>
          <> 
            <TxtCardBody msg={dataForNum1} activeTab={activeTab} />
            {msgsPerScreen === 2 &&
             <TxtCardBody msg={dataForNum2} activeTab={activeTab} />
            }
          </>
         
        </div> 
        <div className={c.mob_rmv_btns}>
           <SliderRightBtn action={() => setAddDispl(true)} />
        </div>       
      </div>
        <div className={c.mob_add_btns}>
           <SliderLeftBtn action={() => setSubstDispl(true)} />
           <SliderRightBtn action={() => setAddDispl(true)} />
        </div> 
      <div className={c.indi_box}>
        <SliderIndicatorsBox 
          indiArray={indiArray} 
          displNum={displNum}
          setDisplNum={setDisplNum}
        />
      </div>   
      </>}

    </div>
    
  )
}

export default TxtMsgs