import { useState, useEffect } from 'react'
import c from './css/slider.module.css'
import { testImages2 } from '../views/TestValues'
import SliderIndicatorsBox from './SliderIndicatorsBox'
import useImagesFmStorage from '../Hooks/useImagesFmStorage';
import SliderLeftBtn from './SliderLeftBtn'
import SliderRightBtn from './SliderRightBtn'

const SliderBig = (props) => {
    //Connects to <Scereen22/>
 
    const [testImages, setTestImages] = useState([])
    const { images } = useImagesFmStorage('forSlider/')
  

    // console.log(images)
// eslint-disable-next-line
    const [msgsPerScreen, setMsgsPerScreen] = useState(6)
    const [displWidth, setDisplWidth] = useState(window.innerWidth)

    const handleResize = () => {
      setDisplWidth(window.innerWidth)
    }

    useEffect(() => {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)        
      }      
    },[])
////////////////////////////////////
    useEffect(() => {
      if(displWidth > 1400) {
        setMsgsPerScreen(6)
      } else if(displWidth <= 1400) {
        setMsgsPerScreen(2)
      }
    },[displWidth])
 ////////////////////////////////////////   

    const [displNum, setDisplNum] = useState(1) 
    const [displImg, setDisplImg] = useState(true)   
    const [addDispl, setAddDispl] = useState(false)
    const [substDispl, setSubstDispl] = useState(false)
    const [indicators, setIndicators] = useState(testImages?.length)
    const [indiArray, setIndiArray] = useState([]);
    const [img1, setImg1] = useState('')
    const [img2, setImg2] = useState(testImages2[1])
    const [img3, setImg3] = useState(testImages2[2])
    const [img4, setImg4] = useState(testImages2[3])
    const [img5, setImg5] = useState(testImages2[4])
    const [img6, setImg6] = useState(testImages2[5])

 useEffect(() => {       
        setTestImages(images)
        setIndicators(images?.length)
        setAddDispl(true) // Tilki dlya <SliderBig/>
  // eslint-disable-next-line 
      }, [images])
///////////////////////////////// ---
   useEffect(() => {
     if(indicators > 0) { 
        if(indiArray?.length < testImages?.length / msgsPerScreen && indiArray?.length < 40) {
          setIndicators(prev => prev - msgsPerScreen)
          setIndiArray([...indiArray, 'i']) 
          //console.log(indiArray?.length, testImages?.length,  msgsPerScreen)
         } 
    //    else if() {       
        
      // }               
     }  
// eslint-disable-next-line  
   }, [indicators])
/////////////////////////////////////// ---
    useEffect(() =>{
        if(addDispl) {
            if(((displNum) * msgsPerScreen) < testImages?.length) {
                //setDisplImg(false)
               setDisplNum(prev => prev + 1)
               setAddDispl(false)
                setTimeout(() => {
                  setDisplImg(true)      
                }, 100)                          
            } else {
            // setDisplImg(false)
                setDisplNum(1)
                setAddDispl(false)
             setTimeout(() => {
              setDisplImg(true)      
             }, 100)   
            }
            
        } else if(substDispl) {
            if((displNum - 1) >= 1) {
           //  setDisplImg(false)
              setDisplNum(prev => prev - 1)
              setSubstDispl(false)
             setTimeout(() => {
                setDisplImg(true)      
               }, 10)               
            } else {
                if(Math.floor(testImages?.length / msgsPerScreen) === testImages?.length / msgsPerScreen) {
            //     setDisplImg(false)
                    setDisplNum(Math.floor(testImages?.length / msgsPerScreen))
                    setSubstDispl(false) 
                 setTimeout(() => {
                        setDisplImg(true)      
                       }, 100) 
                } else {
            //     setDisplImg(false)
                    setDisplNum(Math.floor(testImages?.length / msgsPerScreen) + 1)
                    setSubstDispl(false) 
                  setTimeout(() => {
                        setDisplImg(true)      
                       }, 100)                   
                }    
            }
        }
// eslint-disable-next-line
    }, [addDispl, substDispl])

    useEffect(() =>{      
         setImg1(testImages[((displNum - 1) * msgsPerScreen)])
         setImg2(testImages[((displNum - 1) * msgsPerScreen) + 1])
         setImg3(testImages[((displNum - 1) * msgsPerScreen) + 2])
         setImg4(testImages[((displNum - 1) * msgsPerScreen) + 3])
         setImg5(testImages[((displNum - 1) * msgsPerScreen) + 4])
         setImg6(testImages[((displNum - 1) * msgsPerScreen) + 5]) 
// eslint-disable-next-line
    }, [displNum])


  return (
    <div className={c.slider_wrapper}>
        <div className={`${c.slider_btn_cont} ${c.mob_rmv_btns}`} >
            <SliderLeftBtn action={() => setSubstDispl(true)} />            
        </div>
        <div className={c.slider_body}>
            <div className={c.slider_display}>
                <div className={c.slider_item}>
               {displImg && 
                 <img src={img1} alt="...Loading" className={c.slider_img}/>
               }
                </div>
                <div className={c.slider_item}>
                {displImg &&  
                  <img src={img2} alt="...Loading" className={c.slider_img}/>
                }                
                </div>
              {msgsPerScreen === 6 && 
               <>
                <div className={c.slider_item}>
                {displImg &&  
                  <img src={img3} alt="...Loading" className={c.slider_img}/>
                }
                </div>
                <div className={c.slider_item}>
                {displImg &&  
                  <img src={img4} alt="...Loading" className={c.slider_img}/>
                }
                </div>
                <div className={c.slider_item}>
                {displImg &&  
                  <img src={img5} alt="...Loading" className={c.slider_img}/>
                }
                </div>
                <div className={c.slider_item}>
                {displImg &&  
                  <img src={img6} alt="...Loading" className={c.slider_img}/>
                }
                </div>
              </>
              }
            </div> 
            {displWidth > 950 &&
            <SliderIndicatorsBox 
                indiArray={indiArray} 
                displNum={displNum}
                setDisplNum={setDisplNum}
             />
             }
                
        </div>
        <div className={`${c.slider_btn_cont} ${c.mob_rmv_btns}`}>
           <SliderRightBtn action={() => setAddDispl(true)} />
        </div>
        <div className={c.mob_add_btns}>
           <SliderLeftBtn action={() => setSubstDispl(true)} />
           <SliderRightBtn action={() => setAddDispl(true)} />
        </div> 
    </div>
  )
}

export default SliderBig