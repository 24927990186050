//import { useEffect } from "react";
//import s from "./action_btn.module.css";
import c from "../views/css/screens.module.css";
//import { Link, Element } from 'react-scroll'
//import { useNavigate } from "react-router-dom";

//import { Button } from "react-bootstrap";

import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen21 from "./Screen21";
import Screen22 from "./Screen22";
//import Screen3Controller from "../controllers/Screen3Controller";

import Screen33 from "./Screen33";
import Screen4 from "./Screen4";
const HomeScreen = () => {

 
 
  


  return (
    <div className={`${c.home_screen_wrapper} `}>
      {/* <nav>
        <ul>
          <li><Link to="section1" smooth={true} duration={500}>Section 1</Link></li>
          <li><Link to="section2" smooth={true} duration={500}>Section 2</Link></li>
          <li><Link to="section3" smooth={true} duration={500}>Section 3</Link></li>
          <li><Link to="section4" smooth={true} duration={500}>Section 4</Link></li>
        </ul>
      </nav> */}
      {/* <Element name="section1" className={`${c.section} ${c.sc1_cont}`}>
        <Screen1 />
      </Element>
      
      <Element name="section2" className={`${c.section} ${c.sc1_cont}`}>
         <Screen2 />
      </Element> */}
     <Screen1 />
     <Screen2 />
     <Screen21 />
     <Screen22 />
     <Screen33 />
     <Screen4 />
     {/* <Screen3Controller />   */}
   

    
    </div>
  );
};

export default HomeScreen;

//   {/* {soundOff !== 'yes' ? (
//       <motion.div
//                onClick={() => setSchedric(true)}
//                className={`mt-3 ${c.link}`}
//                whileHover={{
//                 scale: 1.1,
//                 textShadow: '0px 0px 8px rgb(255,255,255)',
//                 color: '#f8e112',
//             }}
//             transition={{ type: 'spring', stiffness: 300 }}
//         >
//             <FormattedMessage id='schedric' defaultMessage={defineMessages.schedric} />
//            <br/>
//            <FormattedMessage id='schedric2' defaultMessage={defineMessages.schedric2} />
//         </motion.div>
//   ) : (
//     <div className={c.no_sound}>
//         <FormattedMessage id='schedric' defaultMessage={defineMessages.schedric} />
//          <br/>
//         <FormattedMessage id='schedric3' defaultMessage={defineMessages.schedric3} />
//     </div>
//   )} */}

//         {/* <motion.div
//                onClick={() => link(`/lesson_completed`, 'no')}
//                className={`mt-3 ${c.link}`}
//                whileHover={{
//                 scale: 1.1,
//                 textShadow: '0px 0px 8px rgb(255,255,255)',
//                 color: '#f8e112',
//             }}
//             transition={{ type: 'spring', stiffness: 300 }}
//         >
//            Lesson End Screen
//         </motion.div> */}

//   <div className={c.lang_intro_wrapper}>
//   <h4>
//    <FormattedMessage id='multLangInt' defaultMessage={defineMessages.multLangInt} />
//   </h4>
//   <div className={c.lang_intro_description} onClick={() => setShowHome(!showHome)}>
//    <FormattedMessage id='chkStg' defaultMessage={defineMessages.chkStg} /> {gearIcon(25)} <FormattedMessage id='toChoose' defaultMessage={defineMessages.toChoose} />
//   </div>

//   <div className={c.flags_container}>
//       <div className={c.flag_item1} onClick={() => context.selectLangString('en')}/>
//       <div className={c.flag_item2} onClick={() => context.selectLangString('ua')} />
//       <div className={c.flag_item3} onClick={() => context.selectLangString('ar')}/>
//   </div>
// </div>

// useEffect(() => {
//     if(schedric && !noRepeat && soundOff !== 'yes') {
//        // console.log('go', schedric, noRepeat);
//         setNoRepeat(true);
//         playSound(Schedric);
//         setTimeout(() => setNoRepeat(false),40000);
//         setTimeout(() => setSchedric(false),35000);
//       }
// // eslint-disable-next-line
// },[schedric, soundOff]);

// import {playSound} from '../Components/PlaySound';
// import Schedric from '../assets/Schedric.mp3';
