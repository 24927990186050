import React from 'react';
import ReactDOM from 'react-dom';
import './bootstrap.min.css';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './Redux/Store';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import LanguageProvider from './Lang/LanguageProvider';



ReactDOM.render( 
        <Provider store={store}> 
             <LanguageProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/*" element={<App/>} />
                    </Routes>
                </BrowserRouter>
             </LanguageProvider> 
        </Provider>,
        document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


        // <CollectionsContext>
        //   <App />
        // </CollectionsContext> 
