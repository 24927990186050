import { globeIcon } from "../Components/Icons";
import c from './Screen.module.css';


const SpinnerBig = () => {
    return (
        <div className={c.spinner_background}>
            <br />
            <br />
          <h1 className={`mt-5 ${c.green_color}`}>
           Loading 
             <br />
           Data
          </h1> 
            <br />
            <br />
          {/* <Spinner animation="border" variant="primary"  /> */}
           
            <br />
            <br />
           
            <div className={`${c.globe_rotate} ${c.green_color}`}>
               {globeIcon(80 ,'var(--btn-color)')} 
            </div>              
        </div> 
    )
}

export default SpinnerBig
