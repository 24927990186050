import React from 'react'
import c from './css/screen2.module.css'
import traveller from '../assets/traveler.png'
import photo_line from '../assets/photo-line-5.png'
import collage from '../assets/collage.png'

import { FormattedMessage } from "react-intl";
import defineMessages from "../Lang/languages/messages";

const Screen2 = () => {

 
  return (
    <div className={`${c.screen_wrapper} ${c.df_c_c} ${c.screen_max_w}`}>
      <div className={`${c.df_c_c} ${c.screen2_header}`}>
           <div className={c.hero_heart}>
             <img src={traveller} alt="hero_heart" className={c.hero_heart_img}/>
           </div>
           <div className={c.header_txt_box}>
             <FormattedMessage id='travels' defaultMessage={defineMessages.travels}/>
           </div> 
           <div>
             <img src={photo_line} alt="hero_heart" className={c.photo_line_img}/>
           </div>
      </div>

      <div className={`${c.df_c_c} ${c.screen2_body}`}>
        <div className={` ${c.body_l_side}`}>
            <div className={` ${c.body_l_side_header}`}>
             <FormattedMessage id='travH1' defaultMessage={defineMessages.travH1}/><br/>
             <FormattedMessage id='travH2' defaultMessage={defineMessages.travH2}/>
           </div>

           <div className={` ${c.body_l_side_txt}`}>
             <p><FormattedMessage id='trav1' defaultMessage={defineMessages.trav1}/></p>
             <p><FormattedMessage id='trav2' defaultMessage={defineMessages.trav2}/></p>
             <p><FormattedMessage id='trav3' defaultMessage={defineMessages.trav3}/></p>
             <p><FormattedMessage id='trav4' defaultMessage={defineMessages.trav4}/></p>
             <p><FormattedMessage id='trav5' defaultMessage={defineMessages.trav5}/></p>
             <p><FormattedMessage id='trav6' defaultMessage={defineMessages.trav6}/></p>
             <p><FormattedMessage id='trav7' defaultMessage={defineMessages.trav7}/></p>                   
           </div>
        </div>

        <div className={` ${c.body_r_side}`}>
            <div className={c.header_txt_box}>
             <img src={collage} alt="hero_heart" className={c.photo_line_img}/>
           </div> 
        </div>

          
          

      </div>

            
                
              
    </div>
  )
}

export default Screen2