import { ArrowIcon } from './Icons'
import c from './css/slider.module.css'


const SliderRightBtn = ({action}) => {
  return (
    <div className={c.slider_btn_cont}>
       <div className={c.btn_bg}  onClick={() => action()} >
         <div className={`${c.slider_btn} ${c.right_btn} `} 
              
                // onClick={() => setSubstDispl(true)}
           >
            {ArrowIcon(32)}
         </div>
       </div>       
    </div>
  )
}

export default SliderRightBtn