
import { defineMessages } from 'react-intl';

export default defineMessages({
  
  hb: "Happy",
  hb2: "Birthday", 
  yd: "Yeva and Dasha",
  wcome: 'Welcome to Our Celebration of',
  bd15: '15th Birthday!',
  yds: 'Yeva and Dasha\'s',
  tday: "Today we celebrate",
  tday1: "the incredible journey",
  tday2: "of our beloved daughters,",
  tday3: "Yeva and Dasha, who turn 15th.",
  tws: "This website is a testament to the love, joy,",
  tws2: "and countless memories we've shared.",
  jus: "Please join us",
  jus2: "as we look back at their beautiful journey",
  jus3: "and send them your heartfelt wishes.",
  travels: "Our Travels",
  congrat: 'Here are congratulations from your friends and family',
  addcongrat: "Add Your Congratulations",
  readyon: "Dear Friend, heartfelt thanks for your visit and congratulations! Please note, this website is currently under development. The final version will be released on 24.01.2024 and to include a photo + video gallery and lots more. Please come to visit us then.",
  yourscongr: "Add your Congratulations for Dasha and Yeva",
 name: "Your Name",
 fname: "Your Family name",
 msg: "Your Message",
 cancel: "Cancel and Close",
 media: "Add Media File",
 postmsg: "Post Your Message",
 avatar: "Add Your Avatar photo",
 avaRcvd: "Image received",
 dldInProg: "Download is in progress",
 whatToAdd: "What do you wish to add?",
 changeAv: "Change Avatar",
 txt: "Text",
 img: "Image",
 vid: "Video clip",
 mus: "Music",
 anothFile: "Another file",
 del: "You are going to delete your post. Are you sure?",
 confDel: "Confirm deleting the post",
 travH1: "From the sun – drenched southern",
travH2: "coast of Turkey, the peaks of Mount Tahtali",
trav1: "and the busy streets of Istanbul, we were immersed in the culture and history of the ancient world.",
trav2: "In magical Budapest, in the heart of Munich, in the fabulous Neuschwanstein Castle, our fabulous moments have become a reality. ",
trav3: "The inspiration at Berlin's Brandenburg Gate and the charm of the old world in Nuremberg reminded us of the eternity of our love. Together, we explored its rich history, always supporting each other. ",
trav4: "The greatness of Rome, the Holy City of the Vatican, and the greatness of St. Peter's Basilica reinforced our love. In the Leaning Tower of Pisa, we were strong and resilient, finding balance and joy in every moment. ",
trav5: "When we crossed the beautiful mountain roads of Austria and reached Imperial Vienna, our bond was regal and strong. In the pompous palaces of Venice and in the narrow streets of Murano, we found treasures in every corner. ",
trav6: "The bright sights of Barcelona and Lisbon became the backdrop for our joint adventures. There, in the charming gardens of Quinta da Regaleira, we explored magical trails and ancient wells, always holding hands and supporting each other. ",
trav7: "In all these amazing places, our love and support for each other remained unchanged.",
sportH: "Our Achievements",
sport: "Dasha and Yeva's Journey of Achievements",
sport1: "From a young age, Dasha and Yeva have shown incredible dedication and talent in various fields. Starting their karate training at just 4 years old, they have participated in numerous tournaments, bringing home many victories and accolades. ",
sport2: "Their passion doesn't stop at karate. The girls also embraced dancing, competing in countless festivals and earning cups, medals, and even the prestigious Grand Prix in Georgia. Their recent mastery of the guitar adds another beautiful note to their list of achievements.",
sport3: "Dasha and Yeva excel in academics as well, showing impressive progress in learning English, German, and Bulgarian languages. Their journey is filled with successes and moments of joy, making them shining stars both in school and beyond.",
sport4: "Their story is a testament to hard work, passion, and the joy of pursuing one’s interests. We are incredibly proud of their accomplishments and look forward to seeing them reach new heights in the future.", 
memo: "Our Memories",
cong1: "Here are congratulations from",
cong2: "your friends and family",
conclH1: "The story of our family is a testament to",
conclH2: "the strength of our bonds, the depth of our love,",
conclH3: "and our unwavering commitment to each other. ",
concl1: "From the busy streets of Vienna to the majestic peaks of Mount Tahtali, from the historic Brandenburg Gate to the charming Quinta da Regaleira, we enjoyed every moment spent together, enriching our lives with beautiful memories. ",
concl2: "Dasha and Eva truly embodied the spirit of perseverance and perfection. Their dedication to karate, dance, music and languages is inspiring. Countless victories at tournaments and festivals clearly demonstrate their hard work and passion.",
concl3: "During our travels, we have always maintained our love and supported each other. Whether we're exploring new places or celebrating personal achievements, our family always sticks together.",
concl4: "We are proud of our Champions! Dasha and Eva, you continue to surprise us with your talents and achievements. Your journey is just beginning, and we look forward to seeing where it will lead you next. Together as a family, we will continue to support and cherish each other, creating memories that will last a lifetime.",
videoCong: "Video greetings",
txtCong: "Text greetings",
audioCong: "Audio greetings",
addCong: "Add a greeting",
cardCong: "PostCards",

});

   

  // contacts: {
  //   header: "Вы можете связаться со мной",
  //   byPhone: "По телефону: ",
  //   messageIn: "Написать мне в:",
  //   or: "или",
  //   messageForm: "Отправьте мне сообщение",
  //   messageFormName: "Ваше имя", 
  //   messageFormMail: "Ваш email",
  //   messageFormSubj: "Тема",
  //   messageFormMsg: "Ваше сообщение",
  //   messageButton: "Отправить",
  //   visitPage: "Посетите мою страницу в:",

  // }