import { useState, useEffect } from 'react';
import { fileStorage } from '../firebase/config';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"

const useStorage = (file, setError) => {

  // const [error, setError] = useState(null);
  const progress = null;
 // const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);
  const fileStorageRef = ref(fileStorage, `images/${file.name}`)

console.log(fileStorageRef)

  useEffect(() => {
    //console.log(file, '- file, fm useStorage line 13')
    // references
    //const storageRef = fileStorage.ref(file.name);
    //const collectionRef = firestore.collection('media');
    
    async function storeData() {
     try {
      console.log('first')
//////////////////////////////////////////////////////////////// 
        const bytes = await uploadBytes(fileStorageRef, file)
/////////////////////////////////////////////////////////////////
        console.log(bytes)
        
        const dowloadURL = await getDownloadURL(fileStorageRef)
        setUrl(dowloadURL)
        console.log(dowloadURL)
       
    } catch (err) {
      //setError(err.message)
      console.log(err)
    }
  }
    // storageRef.put(file).on('state_changed', (snap) => {
    //   let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
    //   setProgress(percentage);
    // }, (err) => {
    //   setError(err);
    // }, async () => {
    //   const url = await storageRef.getDownloadURL();
    //   const createdAt = timestamp();
    //   await collectionRef.add({ url, createdAt });
    //   setUrl(url);
    // });
    storeData()
  }, [file]);

  return { 
    progress, 
    url, 
    //error 
  };
}

export default useStorage;