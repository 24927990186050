import React from 'react'
import c from './css/tabs.module.css'
import PlayVid from './PlayVid'
import s from '../views/css/graph.module.css'
import { useSelector } from 'react-redux'

import DeleteConfirmForm from '../Components/DeleteConfirmForm';

const TxtCardBody = ( {msg, activeTab} ) => {

  
 const user = useSelector((state) => state.user.user)
 console.log(msg)
  
  return (
    <>
    {msg &&
       <div className={c.card}>
            
          {activeTab === 1 && 
          <>
            <div className={c.card_upper_box}>
                <div className={c.card_avatar_box}>
                    <img src={msg?.userImg} alt='Friend' className={c.card_img}/>
                </div>
                  <div className={c.card_name_box}>                  
                    {msg?.name}
                  </div>
            </div>
            {msg?.video && 
              <div className={c.card_audio_box}>
                {/* {msg.video}<br/> */}
                {/* <video controls className={c.post_img_open} >
                  <source src={msg?.video} type={'video/mp4'} />    
                </video>                 */}
                <PlayVid 
                 vid={msg?.video}               
                />
              </div> 
            }
          </>
          
          }
          {activeTab === 2 && msg?.msg &&
            <>
            <div className={c.card_upper_box}>
                <div className={c.card_avatar_box}>
                    <img src={msg?.userImg} alt='Friend' className={c.card_img}/>
                </div>
                  <div className={c.card_name_box}>                  
                    {msg?.name}
                  </div>
            </div>

            <div className={c.card_lower_box}>
                {msg?.msg}
            </div> 
          </>
            
          }
          {activeTab === 3 &&
            <>
            <div className={c.card_upper_box}>
                <div className={c.card_avatar_box}>
                    <img src={msg?.userImg} alt='Friend' className={c.card_img}/>
                </div>
                  <div className={c.card_name_box}>                  
                    {msg?.name}
                  </div>
            </div>

            <div className={c.card_audio_box}>
               <PlayVid 
                 vid={msg?.music}               
                />
               {/* <audio controls>
                 <source src={msg?.music} type={msg?.audioType} />    
               </audio> 
                */}
            </div> 
          </>
           
          }
          {activeTab === 4 &&
            <>
            <div className={c.card_upper_box}>
                <div className={c.card_avatar_box}>
                    <img src={msg?.userImg} alt='Friend' className={c.card_img}/>
                </div>
                  <div className={c.card_name_box}>                  
                    {msg?.name}
                  </div>
            </div>

            <div className={c.card_audio_box}>
              <a href={msg?.image} target='_blank' rel="noreferrer">
                <img src={msg?.image}  alt='postcadr' className={c.post_img_open}/> 
              </a>                 
            </div> 
          </>
          
          }
                {msg?.userId === user?.uid &&
                  <div className={s.trash_bin}>                   
                    <DeleteConfirmForm 
                      collName={'Congrats'}
                      docID={msg.id}
                    />
                  </div>
                  }
                 
             
        </div>}
    </>
  )
}

export default TxtCardBody