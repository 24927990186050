//import {useState} from 'react'
import { PlayIcon, 
    //ArrowDownLeftIcon    
         } from './Icons'
import c from './css/tabs.module.css'

const PlayVid = ( vid ) => {

   /// const [open, setOpen] = useState(false)

  return (
    <div>
        {/* {!open ?   */}
        <div className={c.vid_btn_container}
        //onClick={() => setOpen(true)}
        >
            <a href={vid?.vid} target='_blank' rel="noreferrer">
                {PlayIcon()}  
            </a>
             
        </div>       
          {/* : 
        ( 
        <div className={c.vid_player_container}>          
            <video controls className="post_img_open">
              <source src={vid.vid} type={vidType ? vidType : "video/mp4"} />    
              Your browser does not support the video tag.
            </video>
            <div onClick={() => setOpen(false)}>
                {ArrowDownLeftIcon()}
            </div>
        </div>
         )
        } */}

    </div>
  )
}

export default PlayVid