import { configureStore } from '@reduxjs/toolkit';
//import { apiSlice } from './api/apiSlice'
import { setupListeners } from '@reduxjs/toolkit/query';
import  soundReducer  from './soundSlice';
import bgReducer from './bgSlice';
import userReducer from './userSlice';
import trigerReducer from './trigerSlice'
import  activeTabReducer  from './activeTabSlice';


// export default configureStore({
//     reducer: {
//         rentList: rentReducer,
//         TOJobsList: TOJobsReducer,
//     }
// });

const store = configureStore({
    reducer: {
       sound: soundReducer,
       bg: bgReducer,
       user: userReducer,
       triger: trigerReducer,
       activeTab: activeTabReducer, 
    },
});
export default  store

setupListeners(store.dispatch)


