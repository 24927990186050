import { useState, useEffect } from 'react';
import { fileStorage } from '../firebase/config';  //firestore 
import { ref, getDownloadURL, listAll } from "firebase/storage" 
 // uploadBytes 


const useImagesFmStorage = (colRef) => {

 
  const imagesRef = ref(fileStorage, colRef)
  // const [error, setError] = useState(null);
  
 // const [error, setError] = useState(null);
  const [images, setImages] = useState(null);

  useEffect(() => {
   
    // references
    //const storageRef = fileStorage.ref(file.name);
    //const collectionRef = firestore.collection('media');
   async function getAll() {
    listAll(imagesRef)
    .then(async (res) => {
      const { items } = res;
      const urls = await Promise.all(
        items.map((item) => getDownloadURL(item))
      );
  
      // Array of download URLs of all files in that folder
      setImages(urls);
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
   }  
  // async function storeData() {
      
  //    try {        
  //       const items = await getDownloadURL(imagesRef).then((url) => {
  //         console.log(url)
  //       })
  //       console.log(items)
  //        setImages(items)        
  //   } catch (err) {     
  //     console.log(err)
  //   }
  // }    
    //storeData()
    getAll()
// eslint-disable-next-line
  }, [colRef]);

  return { 
   
    images 
    //error 
  };
}

export default useImagesFmStorage;