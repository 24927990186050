import React, { useState, useRef } from 'react';
import c from './modal.module.css';

import { Alert, Form, Container } from 'react-bootstrap';
import ProgBar from './ProgBar';
//import { thumbsUpIcon } from "./Icons";
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';


const ImgPicker = ( props ) => {

  

    const [file, setFile] = useState(null);
    const [alert, setAlert] = useState('');
    const [error, setError] = useState(null);

  // use Ref позволит запомнить имя файла после окончания его загрузки,
  // иначе, имя выбранного файла будет потеряно вместе с Ререндорингом компонента после
  //окончания загрузки файла на сервер
    const fileName = useRef('');
  ///////////////////////////////////////////////////////////////////  
    
    const fileSelected = (e) => {
        setAlert('');
        let selected = e.target.files[0];
     
        const imageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

  // Проверим выбран ли файл, если да, 
  // то соответствует ли формат файла разрешенному для загрузки?
        if (selected && imageTypes.includes(selected.type)) {
         
            setFile(selected);        
            props.setFileType && props.setFileType(selected.type);
        // т.к. useRef создает объект, нужно пол. доступ к его св-ву current
            fileName.current = selected.name;
//Блокируем работу кнопки отправки и  Предотвращаем возможность отправить данные поездки 
//до полной загрузки фото. Затем, разблокируем кнопку в компоненте ProgBar.
            props.setLoadingImg(true);
        } else {
          console.log(3, 'line 43')
            setFile(null);
            setAlert('An invalid file type has been selected/Вибрано Неприпустимий тип файлу/Избран е невалиден тип файл');
            return;
          }
    };

    return (
        <>
       
        <Container fluid>
                 <Form>
                 {alert &&
                    <Alert variant='danger'>
                      <h5 className={c.alert}>
                          {alert}
                      </h5> 
                    </Alert>
                  }
                  <Form.Group>
                    <Form.File 
                       className={`${c.img_picker_descr} ${props.empty && c.empty}`}
                       type="file" 
                       value={''} 
                       label={props.userImg ? 'Avatar Image/Фото Аватарки/Снимка на Аватар' : 'Optional / Опціонально / Допълнително'}
                       onChange={fileSelected} 
                       lang="uk" 
                       custom/>
                  </Form.Group>
                {file && 
                  <>
                    <Alert variant='info' >
                       <span className={c.alert}>
                        {!error ? (
                          <> 
                            <FormattedMessage id='dldInProg' defaultMessage={defineMessages.dldInProg}/>  - {file.name}                         
                          </>
                          ) : {error}}
                       </span>
                    </Alert> 

                    <ProgBar 
                       file={file} 
                       setFile={setFile}
                       setShowUrl={props.setShowUrl}
                       setImage={props.setImage}
                       setLoadingImg={props.setLoadingImg}
                       setError={setError}
                     />
                   </>                   
                  }
                  {/* {props.showUrl && 
                   <Alert variant='success' className={c.alert_div}>
                    <div>
                       {thumbsUpIcon()}
                    </div>                     
                    <div >
                      <FormattedMessage id='avaRcvd' defaultMessage={defineMessages.avaRcvd}/>                       
                    </div>
                   
                   </Alert> 
                } */}
                 </Form>
        </Container>

        
        
</>
         
    )
}

export default ImgPicker
