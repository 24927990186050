import { ArrowIcon } from './Icons'
import c from './css/slider.module.css'


const SliderLeftBtn = ({action}) => {
  return (
    <div className={c.slider_btn_cont}>
      <div className={c.btn_bg} onClick={() => action()} >
           <div className={`${c.slider_btn} ${c.left_btn} `}                
                // onClick={() => setSubstDispl(true)}
            >
            {ArrowIcon(32)}
          </div>
      </div>
     
    </div>
  )
}

export default SliderLeftBtn