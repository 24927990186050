import { createSlice } from "@reduxjs/toolkit";

 const langSaved = localStorage.getItem('lang')
// console.log(bgSaved) 

const initialState = {
    triger: langSaved || 'ua'
}

export const trigerSlice = createSlice({
    name: 'triger',
    initialState,
    reducers: {
        toggleTriger: (state, action) => {
            state.triger = action.payload;
        //   localStorage.setItem('lang', action.payload);
        },
        // update: (state, action) => {
        //     state.soundOff = action.payload.sound;
        //    // localStorage.setItem('soundOff', action.payload.sound);
        // },
    },
});
 
export const { toggleTriger } = trigerSlice.actions;
export default trigerSlice.reducer;