import React, { useState, useEffect, useRef } from 'react';
import c from './modal.module.css';
import { Alert, Form, Container } from 'react-bootstrap';
import ProgBar from './ProgBar';
//import { thumbsUpIcon } from "./Icons";
import { FormattedMessage } from 'react-intl';
import defineMessages from '../Lang/languages/messages';


const ImgPicker = ( props ) => {

  

    const [file, setFile] = useState(null);
    const [alert, setAlert] = useState('');
    const [error, setError] = useState(null);

  // use Ref позволит запомнить имя файла после окончания его загрузки,
  // иначе, имя выбранного файла будет потеряно вместе с Ререндорингом компонента после
  //окончания загрузки файла на сервер
    const fileName = useRef('');
  ///////////////////////////////////////////////////////////////////  
   const [imageTypes, setImageTypes] = useState([]);
  // const [mediaType, setMediaType] = useState(null);

   const allVideos = ['video/mp4', 'video/avi', 'video/mpeg2', 'video/wmv',
                     'video/mov', 'video/mkv', 'video/mpg', 'video/flv', 
                     'video/webm', 'video/ogv', 'video/3gp', 'video/3gp2', 'video/x-matroska'
                    ]
   const allAudios = ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/wma', 
                        'audio/flac', 'audio/aac', 'audio/ac3', 'audio/mka',
                     ]

   useEffect(() => {
    if(props.video) {
      setImageTypes(allVideos)
      } else if(props.mus) {
        setImageTypes(allAudios)
      }
 // eslint-disable-next-line     
   }, [])

    const fileSelected = (e) => {
        setAlert('');
        let selected = e.target.files[0];
      console.log(selected.type)
      props.setMediaType(selected.type)

      // if(allVideos.includes(selected.type)) {
      //   props.setVideoType(selected.type)
      // } else if(allAudios.includes(selected.type)) {
      //   props.setAudioType(selected.type)
      // }
      
        
  // Проверим выбран ли файл, если да, 
  // то соответствует ли формат файла разрешенному для загрузки?
        if (selected && imageTypes.includes(selected.type)) {
      
            setFile(selected);        
            props.setFileType && props.setFileType(selected.type);
        // т.к. useRef создает объект, нужно пол. доступ к его св-ву current
            fileName.current = selected.name;
//Блокируем работу кнопки отправки и  Предотвращаем возможность отправить данные поездки 
//до полной загрузки фото. Затем, разблокируем кнопку в компоненте ProgBar.
            props.setLoadingImg(true);
       } else {
           setFile(null);
           setAlert('An invalid file type has been selected/Вибрано Неприпустимий тип файлу/Избран е невалиден тип файл');
           return;
         }
    };

    return (
        <>
       
            <Container fluid>
                 <Form>
                 {alert &&
                    <Alert variant='danger'>
                      <h5 className={c.alert}>
                          {alert}
                      </h5> 
                    </Alert>
                  }
                  
                 
                         <Form.Group>                    
                             <Form.File 
                             className={`${c.img_picker_descr} ${props.empty && c.empty}`}
                             type="file" 
                             value={''} 
                             label={'Optional / Опціонально / Допълнително'}
                             onChange={fileSelected} 
                             lang="uk" 
                             custom/>
                         </Form.Group>
                {file && 
                  <>
                    <Alert variant='info' >
                       <span className={c.alert}>
                        {!error ? (
                          <> 
                            <FormattedMessage id='dldInProg' defaultMessage={defineMessages.dldInProg}/>  - {file.name}                         
                          </>
                          ) : {error}}
                       </span>
                    </Alert> 

                    <ProgBar 
                       file={file} 
                       setFile={setFile}
                       setShowUrl={props.setShowUrl}
                       setImage={props.setImage}
                       setLoadingImg={props.setLoadingImg}
                       setError={setError}
                     />
                   </>                   
                  }
                
                </Form>
        </Container>

        
        
</>
         
    )
}

export default ImgPicker
